$(document).ready(function () {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  $(".main-header__tools").append(
    '<button class="hamburger hamburger--spring is-flex" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="is-sr-only">Menú</span></button>'
  );
  $("#menu").attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".hamburger");
  var menu = document.querySelector("#menu");

  if (toggleMenu) {
    toggleMenu.addEventListener("click", function () {
      var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
      toggleMenu.setAttribute("aria-expanded", !open);
      menu.hidden = !menu.hidden;
    });
  }

  $(".hamburger").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    $("html").toggleClass("js-menu-open");
  });

  // -------------------------------
  // Menú principal amb desplegables (https://www.w3.org/WAI/tutorials/menus/flyout/#use-button-as-toggle)
  // -------------------------------

  var menuItems = document.querySelectorAll("#menu li.has-submenu");
  Array.prototype.forEach.call(menuItems, function (el, i) {
    var activatingA = el.querySelector("a");
    var btn =
      '<button class="pt-xs pb-xs pl-xs"><span><span class="is-sr-only">Mostra el submenú per “' +
      activatingA.text +
      "”</span></span></button>";
    activatingA.insertAdjacentHTML("afterend", btn);
    // activatingA.setAttribute("aria-expanded", "false");

    el.querySelector("button").addEventListener("click", function (event) {
      var currentMenu = this.parentNode;
      Array.prototype.forEach.call(menuItems, function (el, i) {
        if (currentMenu !== el) {
          el.classList.remove("open");
          el.classList.add("close");
          //el.querySelector("a").setAttribute("aria-expanded", "false");
          el.querySelector("button").setAttribute("aria-expanded", "false");
        }
      });
      //console.log(this.parentNode.className);
      if (this.parentNode.classList.contains("close")) {
        this.parentNode.classList.add("open");
        this.parentNode.classList.remove("close");
        /*this.parentNode
          .querySelector("a")
          .setAttribute("aria-expanded", "true");*/
        this.parentNode
          .querySelector("button")
          .setAttribute("aria-expanded", "true");
      } else {
        this.parentNode.classList.add("close");
        this.parentNode.classList.remove("open");
        /*this.parentNode
          .querySelector("a")
          .setAttribute("aria-expanded", "false");*/
        this.parentNode
          .querySelector("button")
          .setAttribute("aria-expanded", "false");
      }
      event.preventDefault();
    });
  });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // scroll top
  // ----------
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("visible");
    } else {
      $(".scrolltop").removeClass("visible");
    }
  });

  // ------------------------------------------
  // Random colors per a les caixes de notícies
  // ------------------------------------------
  $(".random-color").each(function () {
    const number = 1 + Math.floor(Math.random() * 4);
    $(this).addClass("has-text-color-" + number);
    $(this).parents("body").not(".home").addClass("page-color-" + number);
  });


  // -------
  // Sliders
  // -------
  // $(".home-slider").slick({
  //   dots: true,
  //   arrows: false,
  //   autoplay: true,
  //   autoplaySpeed: 10000,
  //   fade: true,
  // });

  // --------
  // headroom
  // --------
  $(".main-header").headroom({
    offset: 205,
    tolerance: 5,
    classes: {
      initial: "animated",
      pinned: "slideDown",
      unpinned: "slideUp",
    },
  });

  // --------
  // timeline
  // --------
  $(".timeline").timelify({
    animLeft: "fadeInLeft",
    animCenter: "fadeInUp",
    animRight: "fadeInRight",
    animSpeed: 600,
    offset: 150,
  });

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function (index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".tab-panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function (tab, i) {
        tab.setAttribute("role", "tab");
        //	    tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function (e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function (e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
              ? index + 1
              : e.which === 40
              ? "down"
              : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
              ? switchTab(e.currentTarget, tabs[dir])
              : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function (panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }
});

// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
(function () {
  // Get all the headings
  const headings = document.querySelectorAll(".collapsible__title");

  Array.prototype.forEach.call(headings, (heading) => {
    // Give each <h2> a toggle button child
    // with the SVG plus/minus icon
    heading.innerHTML = `
      <button aria-expanded="false" class="is-flex is-justify-content-space-between is-align-items-center">
        ${heading.textContent}
        <svg class="icon is-flex-shrink-0 ml-s" aria-hidden="true" focusable="false"><g fill="none" fill-rule="evenodd" stroke="#000" stroke-width="2" transform="rotate(180 10 10)"><circle cx="9.5" cy="9.5" r="9.5"/><path stroke-linecap="round" stroke-linejoin="round" d="M5.074 11.047 9.5 6.017M14.056 11.047 9.5 6.017"/></g></svg>
        <svg class="icon is-flex-shrink-0 ml-s" aria-hidden="true" focusable="false"><g fill="none" fill-rule="evenodd" stroke-width="2" transform="translate(1 1)"><circle cx="9.5" cy="9.5" r="9.5"/><path stroke-linecap="round" stroke-linejoin="round" d="m6.036 13.017 6.994-7M13.03 13.016l-6.994-7"/></g></svg>
      </button>
    `;
    // Function to create a node list
    // of the content between this heading and the next
    const getContent = (elem) => {
      let elems = [];
      while (
        elem.nextElementSibling &&
        elem.nextElementSibling.className !== "collapsible__title"
      ) {
        elems.push(elem.nextElementSibling);
        elem = elem.nextElementSibling;
      }

      // Delete the old versions of the content nodes
      elems.forEach((node) => {
        node.parentNode.removeChild(node);
      });

      return elems;
    };

    // Assign the contents to be expanded/collapsed (array)
    let contents = getContent(heading);

    // Create a wrapper element for `contents` and hide it
    let wrapper = document.createElement("div");
    wrapper.classList.add("flow", "p-m");
    wrapper.hidden = true;

    // Add each element of `contents` to `wrapper`
    contents.forEach((node) => {
      wrapper.appendChild(node);
    });

    // Add the wrapped content back into the DOM
    // after the heading
    heading.parentNode.insertBefore(wrapper, heading.nextElementSibling);

    // Assign the button
    let btn = heading.querySelector("button");

    btn.onclick = () => {
      // Cast the state as a boolean
      let expanded = btn.getAttribute("aria-expanded") === "true" || false;

      // Switch the state
      btn.setAttribute("aria-expanded", !expanded);
      // Switch the content's visibility
      wrapper.hidden = expanded;
    };
  });

  if (
    $(".collapsible__title").length > 0 &&
    window.location.hash.indexOf("#entry-") > -1 &&
    $(window.location.hash).length > 0
  ) {
    $(window.location.hash).find("button").attr("aria-expanded", true);
    $(window.location.hash).next().show();
    $("html, body").animate(
      {
        scrollTop: $(window.location.hash).parent().offset().top,
      },
      1000
    );
  }
})();

/* Cercador de professorat a organització */
$(document).ready(function () {
  $("body").on("submit", "#form-professorat", function (event) {
    event.preventDefault();
    var value = $("#cerca-professor").val();
    $.post({
      url: "ajaxCerca.php",
      data: { cerca: value },
    }).done(function (data) {
      $(".resultats-cerca").html(data);
    });
  });

  $("body").on("change keyup", "#cerca-professor", function (event) {
    event.preventDefault();
    var value = $(this).val();
    $.post({
      url: "ajaxCerca.php",
      data: { cerca: value },
    }).done(function (data) {
      $(".resultats-cerca").html(data);
    });
  });

  if ($(".ofertaFormativaSearch").length > 0) {
    $(".ofertaFormativaSearch").each(function () {
      $(this)
        .find("input")
        .autocomplete({
          serviceUrl: $(this).attr("action"),
          minChars: 3,
          paramName: "keywords",
          onSelect: function (suggestion) {
            window.location.href = suggestion.data;
          },
        });
    });
  }
});
